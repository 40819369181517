import { Box, Typography, CircularProgress } from "@mui/material"
import AddButton from "../../components/shared/AddButton"
import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetHouseById from "../Organizations/hooks/useGetHouseById"
import { scopes } from "../../scopes"
import styles from "./GuestListsScreen.module.css"
import useGetAttendanceItems from "./hooks/useGetAttendanceItems"
import AttendanceItemDialog from "./components/AttendanceItemDialog"
import AttendanceItemTable from "./components/AttendanceItemTable"

const AttendanceScreen: FunctionComponent = () => {
  const [createAttendanceItemOpen, setCreateAttendanceItemOpen] =
    useState(false)

  const {
    getHouseById,
    house: organization,
    loading: organizationLoading,
    error: organizationError,
  } = useGetHouseById()
  const {
    getAttendanceItems,
    paginationData,
    attendanceItems,
    loading: attendanceItemsLoading,
    error: attendanceItemsError,
  } = useGetAttendanceItems()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organizationId = localStorage.getItem("activeOrganizationId")

        if (organizationId) {
          await getHouseById(Number(organizationId))
          await getAttendanceItems(Number(organizationId))

          if (organization) {
            localStorage.setItem(
              "activeOrganization",
              JSON.stringify(organization)
            )
          }
        }
      } catch (err) {
        console.error(`Error loading organization: ${organizationError}`)
      }
    }

    fetchData()
  }, [])

  if (organizationLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  return (
    <>
      <ScreenContainer requiresScope={scopes.organization.attendanceItems.read}>
        <Box
          className={styles.createButtonContainer}
          onClick={() => setCreateAttendanceItemOpen(true)}
        >
          <AddButton className="className" />
        </Box>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            mb: "24px",
          }}
          className={styles.guestListsScreen}
        >
          {attendanceItemsError && (
            <ErrorComponent error={attendanceItemsError} />
          )}
          {attendanceItemsLoading && <CircularProgress />}
          <Typography variant="h3" className={styles.title}>
            Attendance
          </Typography>
          <AttendanceItemTable
            attendanceItems={attendanceItems}
            paginationData={paginationData}
          />
        </Box>
        <AttendanceItemDialog
          isOpen={createAttendanceItemOpen}
          close={() => setCreateAttendanceItemOpen(false)}
          onSuccess={() => getAttendanceItems(organization?.id!)}
        />
      </ScreenContainer>
    </>
  )
}

export default AttendanceScreen
