import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material"
import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetMyHouses from "../Users/hooks/useGetMyHouses"
import useGetHouseById from "../Organizations/hooks/useGetHouseById"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import useGetDashboard from "./hooks/useGetDashboard"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../services/auth-service"
import { scopes } from "../../scopes"
import AnnouncementDialog from "../Announcements/components/AnnouncementDialog"
import { styled } from "@mui/system"
import EditIcon from "@mui/icons-material/Edit"
import { Announcement } from "../../types"
import styles from "./DashboardScreen.module.css"
import { lightTheme } from "../../constants/theme"
import PollCard from "./components/PollCard"

const DashboardScreen: FunctionComponent = () => {
  const [announcementDialogOpen, setAnnouncementDialogOpen] = useState(false)
  const [updateAnnouncementDialogOpen, setUpdateAnnouncementDialogOpen] =
    useState(false)
  const [selectedAnnouncement, setSelectedAnnouncement] =
    useState<Announcement>()

  const { hasScope } = useAuth()

  const { getMyHouses, houses, loading, error } = useGetMyHouses()
  const {
    getHouseById,
    house,
    loading: organizationLoading,
    error: houseError,
  } = useGetHouseById()
  const {
    getOrganizationDashboard,
    dashboard,
    loading: dashboardLoading,
  } = useGetDashboard()

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userHouses = await getMyHouses()

        if (userHouses) {
          if (userHouses.length === 0) {
            navigate("/welcome")
          }

          if (
            userHouses.length > 0 &&
            localStorage.getItem("activeOrganizationId") === null
          ) {
            localStorage.setItem("activeOrganizationId", `${userHouses[0].id}`)
            localStorage.setItem(
              "activeOrganization",
              JSON.stringify(userHouses[0])
            )
            window.location.reload()
          }
        }
      } catch (err) {
        console.error(`Error loading houses: ${error}`)
      }
    }

    fetchData()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organizationId = localStorage.getItem("activeOrganizationId")

        if (organizationId) {
          await getHouseById(Number(organizationId))
          const dashData = await getOrganizationDashboard(
            Number(organizationId)
          )

          if (house) {
            localStorage.setItem("activeOrganization", JSON.stringify(house))
          }
        }
      } catch (err) {
        console.error(`Error loading organization: ${houseError}`)
      }
    }

    fetchData()
  }, [])

  if (loading || organizationLoading || dashboardLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  // Define custom progress bars
  const YellowProgressBar = styled(LinearProgress)({
    "& .MuiLinearProgress-bar": {
      backgroundColor: "orange",
    },
  })

  const GreenProgressBar = styled(LinearProgress)({
    "& .MuiLinearProgress-bar": {
      backgroundColor: "green",
    },
  })

  return (
    <ScreenContainer>
      <Box className={styles.titleContainer}>
        <Typography variant="h3" className={styles.title}>
          {house?.name}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {error && (
          <Grid item xs={12}>
            <ErrorComponent error={error} />
          </Grid>
        )}
        {dashboard?.billing?.alerts && dashboard.billing.alerts.length > 0 && (
          <Grid item xs={12}>
            {dashboard.billing.alerts.map((alert) => (
              <Card style={{ marginTop: 10 }}>
                <CardContent>
                  <Box display="flex" flexDirection="column" height="100%">
                    <Box mb="auto">
                      <Typography variant="h6">
                        <b>{alert.title}</b>
                      </Typography>
                      <Typography variant="body2">{alert.message}</Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-end"
                    >
                      <Button
                        href={alert.cta.link}
                        style={{
                          textTransform: "none",
                          padding: 0,
                        }}
                      >
                        {alert.cta.label}
                      </Button>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={8} lg={8}>
          {dashboard?.announcements && (
            <Box
              className={styles.announcementsContainer}
              sx={{ border: `solid 2px ${lightTheme.palette.secondary.main}` }}
            >
              <Typography variant="h5" sx={{ marginBottom: "0px" }}>
                <b>Announcements</b>
                {hasScope(scopes.organization.announcements.create) && (
                  <IconButton onClick={() => setAnnouncementDialogOpen(true)}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                )}
              </Typography>
              {dashboard.announcements.map((announcement) => (
                <Box width="100%">
                  <Card
                    style={{
                      marginTop: 10,
                      position: "relative",
                      boxShadow: "none",
                      borderRadius: "none",
                      borderBottom: `solid 1px ${lightTheme.palette.secondary.main}`,
                    }}
                  >
                    {hasScope(scopes.organization.announcement.update) && (
                      <IconButton
                        style={{ position: "absolute", right: 0, top: 0 }}
                        onClick={() => navigate("/announcements")}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    <CardContent>
                      <Typography variant="h6">
                        <b>{announcement.title}</b>
                      </Typography>
                      <Typography variant="body2">
                        {announcement.message}
                      </Typography>
                      <br />
                      <Typography
                        variant="body2"
                        style={{
                          position: "absolute",
                          bottom: 10,
                          left: 16,
                        }}
                      >
                        {announcement.author.name} -{" "}
                        {new Date(announcement.created_at).toLocaleString()}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              ))}
            </Box>
          )}
          {dashboard?.guest_lists && (
            <Box
              className={styles.guestListContainer}
              sx={{ border: `solid 2px ${lightTheme.palette.secondary.main}` }}
            >
              <Typography variant="h5" sx={{ marginBottom: "20px" }}>
                <b>Guest Lists</b>
              </Typography>
              {dashboard.guest_lists.map((guestList) => (
                <Box
                  className={styles.guestListSingleContainer}
                  sx={{
                    borderBottom: `solid 1px ${lightTheme.palette.secondary.main}`,
                  }}
                >
                  <Box className={styles.nameAndGuestsContainer}>
                    <Typography variant="h5" className={styles.guestListTitle}>
                      <b>{guestList.name}</b>
                      {hasScope(scopes.organization.guestList.guests.create) &&
                      guestList.max_guests > guestList.guests.length ? (
                        <IconButton
                          onClick={() =>
                            navigate(
                              `/guest-lists/${guestList.id}?addGuest=true`
                            )
                          }
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      ) : null}
                    </Typography>
                    <Box className={styles.guestsContainer}>
                      {guestList.guests.length === 0 && (
                        <p>
                          You haven't added any guests to this guest list yet
                        </p>
                      )}
                      {guestList.guests.map((guest, index) => {
                        if (index < 2) {
                          return (
                            <div key={index} style={{ marginTop: 2 }}>
                              <div>
                                <p style={{ marginTop: 10, marginLeft: 5 }}>
                                  <b>{guest.name}</b>
                                  {guestList.guests.length === 2 &&
                                    `${index === 0 ? " and" : ""}`}
                                  {guestList.guests.length > 2 &&
                                    `${index === 0 ? ", " : ""} ${
                                      index === 1
                                        ? `and ${
                                            guestList.guests.length - 2
                                          } others`
                                        : ""
                                    }`}
                                </p>
                              </div>
                            </div>
                          )
                        } else {
                          return null
                        }
                      })}
                    </Box>
                  </Box>
                  <Button
                    style={{ textTransform: "none" }}
                    onClick={() => navigate(`/guest-lists/${guestList.id}`)}
                  >
                    View all guests
                  </Button>
                </Box>
              ))}
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          {dashboard?.study_hours && (
            <Box
              className={styles.studyHoursContainer}
              sx={{ border: `solid 2px ${lightTheme.palette.secondary.main}` }}
            >
              <Typography variant="h5">
                <b>Study Hours</b>
              </Typography>
              <Box className={styles.studyHoursBar}>
                {dashboard.study_hours.percentage < 80 && (
                  <LinearProgress
                    variant="determinate"
                    value={dashboard.study_hours.percentage}
                  />
                )}
                {dashboard.study_hours.percentage >= 80 &&
                  dashboard.study_hours.percentage < 100 && (
                    <YellowProgressBar
                      variant="determinate"
                      value={dashboard.study_hours.percentage}
                    />
                  )}
                {dashboard.study_hours.percentage >= 100 && (
                  <GreenProgressBar
                    variant="determinate"
                    value={dashboard.study_hours.percentage}
                  />
                )}
                <Typography sx={{ marginTop: "5px" }}>
                  {dashboard.study_hours.percentage}% complete
                </Typography>
              </Box>
              <Typography sx={{ textAlign: "center" }}>
                You currently have <b>{dashboard.study_hours.total}</b> out of{" "}
                <b>{dashboard.study_hours.required}</b> required study hour
                {dashboard.study_hours.required === 1 ? "" : "s"} this week.
              </Typography>
            </Box>
          )}
          {dashboard?.polls &&
            dashboard.polls.map((poll) => <PollCard poll={poll} />)}
          {dashboard?.members && (
            <Box
              className={styles.membersContainer}
              sx={{ border: `solid 2px ${lightTheme.palette.secondary.main}` }}
            >
              <Typography variant="h5">
                <b>Members</b>
                {hasScope(scopes.organization.inviteCodes.create) && (
                  <IconButton
                    onClick={() => navigate("/members?inviteOpen=true")}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                )}
              </Typography>
              <Box className={styles.membersInnerContainer}>
                {dashboard.members.map((houseMember) => (
                  <div style={{ marginTop: 2 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Avatar
                        alt={houseMember.user.name}
                        src={`${houseMember.user.profile_photo}`}
                      />
                      <p style={{ marginTop: 10, marginLeft: 5 }}>
                        {houseMember.user.name}
                      </p>
                    </div>
                  </div>
                ))}
              </Box>
              {dashboard.members_count > 5 && (
                <Button
                  style={{ textTransform: "none" }}
                  onClick={() => navigate("/members")}
                >
                  View all
                </Button>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </ScreenContainer>
  )
}

export default DashboardScreen
