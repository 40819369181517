// DO NOT EDIT FILE DIRECTLY
// Get the latest export from the API repo
export const scopes = {
  organizations: {
    create: "organizations.create",
    read: "organizations.read",
    search: "organizations.search",
  },
  organization: {
    read: "organization.read",
    update: "organization.update",
    delete: "organization.delete",
    announcements: {
      create: "organization:announcements.create",
      read: "organization:announcements.read",
    },
    announcement: {
      read: "organization:announcement.read",
      update: "organization:announcement.update",
      delete: "organization:announcement.delete",
    },
    attendanceItems: {
      create: "organization:attendance-items.create",
      read: "organization:attendance-items.read",
    },
    attendanceItem: {
      read: "organization:attendance-item.read",
      update: "organization:attendance-item.update",
      delete: "organization:attendance-item.delete",
    },
    billing: {
      manage: "organization:billing.manage",
    },
    events: {
      create: "organization:events.create",
      read: "organization:events.read",
    },
    event: {
      read: "organization:event.read",
      update: "organization:event.update",
      delete: "organization:event.delete",
    },
    guestLists: {
      read: "organization:guest-lists.read",
      create: "organization:guest-lists.create",
    },
    guestList: {
      read: "organization:guest-list.read",
      update: "organization:guest-list.update",
      delete: "organization:guest-list.delete",
      guests: {
        read: "organization:guest-list:guests.read",
        create: "organization:guest-list:guests.create",
      },
      guest: {
        read: "organization:guest-list:guest.read",
        update: "organization:guest-list:guest.update",
        delete: "organization:guest-list:guest.delete",
      },
    },
    inviteCodes: {
      create: "organization:invite-codes.create",
      read: "organization:invite-codes.read",
    },
    inviteCode: {
      accept: "organization:invite-code.accept",
      read: "organization:invite-code.read",
      update: "organization:invite-code.update",
      delete: "organization:invite-code.delete",
    },
    lists: {
      create: "organization:lists.create",
      read: "organization:lists.read",
    },
    list: {
      read: "organization:list.read",
      update: "organization:list.update",
      delete: "organization:list.delete",
    },
    members: {
      create: "organization:members.create",
      read: "organization:members.read",
      studyHours: {
        read: "organization:members:study-hours.read",
      },
    },
    member: {
      read: "organization:member.read",
      update: "organization:member.update",
      delete: "organization:member.delete",
      points: {
        create: "organization:member:points.create",
        read: "organization:member:points.read",
        delete: "organization:member:points.delete",
      },
      studyHours: {
        read: "organization:member:study-hours.read",
        update: "organization:member:study-hours.update",
      },
    },
    pointItems: {
      create: "organization:point-items.create",
      read: "organization:point-items.read",
    },
    pointItem: {
      read: "organization:point-item.read",
      update: "organization:point-item.update",
      delete: "organization:point-item.delete",
    },
    polls: {
      create: "organization:polls.create",
      read: "organization:polls.read",
    },
    poll: {
      read: "organization:poll.read",
      update: "organization:poll.update",
      respond: "organization:poll.respond",
      end: "organization:poll.end",
      delete: "organization:poll.delete",
    },
    studyHours: {
      locations: {
        create: "organization:study-hours:locations.create",
        read: "organization:study-hours:locations.read",
      },
      location: {
        read: "organization:study-hours:location.read",
        update: "organization:study-hours:location.update",
        delete: "organization:study-hours:location.delete",
      },
      sessions: {
        create: "organization:study-hours:sessions.create",
        read: "organization:study-hours:sessions.read",
      },
      session: {
        read: "organization:study-hours:session.read",
        update: "organization:study-hours:session.update",
        delete: "organization:study-hours:session.delete",
      },
    },
  },
  schools: {
    create: "schools.create",
    read: "schools.read",
  },
  school: {
    organizations: {
      create: "school:organizations.create",
      read: "school:organizations.read",
    },
  },
  studyHours: {
    sessions: {
      create: "study-hours:sessions.create",
      read: "study-hours:sessions.read",
    },
    session: {
      read: "study-hours:session.read",
      update: "study-hours:session.update",
      delete: "study-hours:session.delete",
    },
  },
  users: {
    read: "users.read",
    create: "users.create",
    search: "users.search",
  },
  user: {
    read: "user.read",
    update: "user.update",
    delete: {
      read: "user:delete.read",
      request: "user:delete.request",
      process: "user:delete.process",
    },
    notes: {
      create: "user:notes.create",
      read: "user:notes.read",
    },
    note: {
      read: "user:note.read",
      update: "user:note.update",
      delete: "user:note.delete",
    },
    notifications: {
      read: "user:notifications.read",
    },
  },
}
